import { graphql } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'

const HeroContainerStyles = styled.div`
  display: inline-block;
  position: relative;
  .hero__image {
    position: relative;
  }
  .hero__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 2rem;
  }
  .hero__overlay-inner {
    max-width: var(--width-medium);
    margin: auto;
    padding: 0.5rem;
    background-color: var(--header-bg-color);
    opacity: 0.95;
    @media only screen and (min-width: 400px) {
      padding: 2rem;
      .hero__overlay-text {
        margin: 0 2rem;
      }
    }
    @media only screen and (max-width: 600px) {
      .hero__overlay-text {
        font-size: 1.25rem;
      }
    }
  }
  .hero__overlay-text {
    text-align: center;
    color: var(--header-text-color);
  }
`

export default function IndexPage({ data }) {
  return (
    <>
      <Layout>
        <SEO />
        {data.allTaxonomyTermMicrosites.edges
          .filter(
            thisMicrositesContent =>
              parseInt(thisMicrositesContent.node.drupal_internal__tid) ===
              parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
          )
          .map(thisMicrosite => (
            <>
              {thisMicrosite?.node?.relationships?.field_ms_hero_image ? (
                <HeroContainerStyles className="hero">
                  <GatsbyImage
                    className="hero__image"
                    image={
                      thisMicrosite.node.relationships.field_ms_hero_image
                        .localFile.childImageSharp.gatsbyImageData
                    }
                    alt={
                      thisMicrosite.node.field_ms_hero_image.alt
                        ? thisMicrosite.node.field_ms_hero_image.alt
                        : thisMicrosite.node.name
                    }
                  />

                  {thisMicrosite.node.field_ms_hero_text ? (
                    <>
                      <div className="hero__overlay">
                        <div className="hero__overlay-inner">
                          <h2 className="hero__overlay-text">
                            {thisMicrosite.node.field_ms_hero_text}
                          </h2>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </HeroContainerStyles>
              ) : (
                ''
              )}
            </>
          ))}

        {data.allTaxonomyTermMicrosites.edges
          .filter(
            thisMicrositesContent =>
              parseInt(thisMicrositesContent.node.drupal_internal__tid) ===
              parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
          )
          .map(thisMicrosite => (
            <>
              <div className="layout-contained padding-horizontal">
                {thisMicrosite.node.field_ms_hero_text ? (
                  <h1>{thisMicrosite.node.field_ms_hero_text}</h1>
                ) : (
                  <h1>{thisMicrosite.node.name}</h1>
                )
                }
                <div
                  dangerouslySetInnerHTML={{
                    __html: thisMicrosite.node.field_ms_landing_text.value,
                  }}
                />
              </div>
            </>
          ))}
      </Layout>
    </>
  )
}

export const query = graphql`
  query homePage {
    allTaxonomyTermMicrosites {
      edges {
        node {
          field_ms_landing_text {
            value
          }
          name
          drupal_internal__tid
          field_ms_hero_text
          field_ms_hero_image {
            alt
          }
          relationships {
            field_ms_hero_image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }
  }
`
